$gutter: 0.9375rem;

html {
    height: 100vh;
}

body {
    margin: 0;
    height: 100vh;
}

.main {
    background: url('../assets/haha.jpg') repeat;
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 5rem;
    padding-left: $gutter;
    padding-right: $gutter;

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255, 0.95);
        z-index: 1;
        content: '';
    }

    &__content {
        position: relative;
        z-index: 2;
    }
}

.joke {
    background: #fff;
    padding: 1rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    max-width: 30rem;
    min-height: 12.5rem;
    display: flex;
    flex-direction: column;

    #joke-punchline {
        font-weight: 700;
    }

    &__title {
        position: relative;
        z-index: 1;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: gold;
            height: 40%;
            width: 100%;
            z-index: 1;
            transform: translateY(10px) rotate(-1deg) skew(-15deg);
        }

        h1 {
            position: relative;
            z-index: 2;
            text-align: center;
        }
    }

    &__nav {
        margin-top: auto;
    }
}

h1 {
    margin: 0;
}

.hidden {
    display: none;
}
